import { Box, Button, Container, Grid, TextField } from '@material-ui/core'
import React, { useCallback, useState } from 'react'
import { sendRequest } from '../../config/Api'
import { useAccount } from '../../utils/AccountContext'
import { useDialog } from '../../utils/Dialog'



const ChangePassword: React.FC = () => {
    const [senha, setSenha] = useState<string>("")
    const [senha2, setSenha2] = useState<string>("")
    const [senha3, setSenha3] = useState<string>("")
    const dialog = useDialog()
    const account = useAccount()

    const changePassWord = useCallback(async () => {
        try {
            const userName = account.userName
            if (userName!=null) {
                dialog.showLoading('Alterando senha')
                const body = {
                    newPassword: senha2,
                    oldPassword: senha
                }
                const response: Response = await sendRequest('accounts/change-password', body)
                const json = await response.json()
                if (response.status === 200) {
                    account.handleLogoff()
                    dialog.showSnackbar('Senha alterada com sucesso! Faça login com a nova senha', 'success')
                } else {
                    dialog.closeLoading()
                    dialog.showSnackbar(json.error, 'error')
                }
            }
        } catch (error) {
            console.log(error)
            dialog.closeLoading()
            dialog.showSnackbar('Erro ao alterar a senha', 'error')
        }
    }, [dialog, account, senha, senha2])

    return (
        <React.Fragment>
            <Container maxWidth="sm" component="div">
                <Grid container spacing={2}>
                    <Grid item sm={12}>
                        <TextField type="password" fullWidth={true} id="outlined-basic" label="Senha atual" variant="outlined" onChange={(event) => { setSenha(event.target.value) }} />
                    </Grid>
                    <Grid item sm={12}>
                        <TextField type="password" fullWidth={true} id="outlined-basic" label="Nova senha" variant="outlined" onChange={(event) => { setSenha2(event.target.value) }} />
                    </Grid>
                    <Grid item sm={12}>
                        <TextField type="password" fullWidth={true} id="outlined-basic" label="Repita a nova senha" variant="outlined" onChange={(event) => { setSenha3(event.target.value) }} />
                    </Grid>
                    <Grid item sm={12} >
                        <Box display="flex" flexDirection="col" justifyContent="flex-end">
                            <Button
                                onClick={(event)=>changePassWord()}
                                disabled={senha == null || senha === '' || senha2 == null || senha3 == null || senha2.trim() === '' || senha2 !== senha3}
                                variant="contained"
                                color="primary">Mudar senha</Button>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </React.Fragment>
    )
}

export default ChangePassword