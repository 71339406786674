import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import { ForumPost } from "../../config/types";
import { useDialog } from "../../utils/Dialog";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ReactHtmlParser from "react-html-parser";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: "70.33%",
      flexShrink: 0,
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary,
    },
  })
);

const Forum: React.FC = () => {
  const classes = useStyles();
  const dialog = useDialog();
  const [notices, setNotices] = useState<ForumPost[]>([]);
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const loadNotices = useCallback(() => {
    fetch(`http://mufantasy.com.br/Launcher/forum.php`, {
      method: "GET",
    })
      .then(async (value: Response) => {
        const data: ForumPost[] = await value.json();
        setNotices(data);
        dialog.closeLoading();
      })
      .catch((reason) => {
        dialog.showSnackbar("Erro ao carregar notícias do fórum");
      });
  }, [dialog]);

  useEffect(() => {
    loadNotices();
    dialog.showLoading("Carregando notícias do servidor");
  }, [dialog, loadNotices]);

  return (
    <React.Fragment>
      <div>
        {notices.map((notice, index) => (
          <Accordion
            expanded={expanded === `panel${index}`}
            onChange={handleChange(`panel${index}`)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Box>
                <Typography className={classes.heading}>
                  {notice.titulo}
                </Typography>
                <Typography className={classes.secondaryHeading}>
                  {notice.data}
                </Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              {ReactHtmlParser(notice.mensagem)}
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
    </React.Fragment>
  );
};

export default Forum;
