/* eslint-disable jsx-a11y/anchor-is-valid */
import { Grid, makeStyles, Typography } from '@material-ui/core';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import 'fontsource-roboto'; /**Must be imported (entry-point) */
import React, { useCallback, useEffect, useState } from 'react';
import Carrousel from '../components/Carousel';
import LoadingSpinner from '../components/LoadingSpinner';
import Main from '../components/Main';
import Sidebar from '../components/Sidebar';
import { sendGetRequest } from '../config/Api';
import { ForumPost, ServerStat } from '../config/types';
import { useDialog } from '../utils/Dialog';

const useStyles = makeStyles((theme) => ({
    mainGrid: {
        marginTop: theme.spacing(3),
    },
}));


const Home = () => {
    const classes = useStyles();
    const dialog = useDialog();

    const loadStatus = async () => {
        return new Promise(async (resolve: (value: ServerStat) => void, reject) => {
            const response: Response = await sendGetRequest('server/status')
            const json = await response.json()
            if (response.status === 200) {
                resolve((json.response))
            } else {
                dialog.showSnackbar("Erro ao carregar status do servidor", "error")
                reject()
            }
        })
    }

    const sidebar = {
        title: 'Status',
        description: '',
        archives: [],
        social: [
            // { name: 'GitHub', icon: GitHubIcon },
            // { name: 'Twitter', icon: TwitterIcon },
            { name: 'Facebook', icon: FacebookIcon },
        ],
    };

    return (
        <React.Fragment>
            <Carrousel />
            <Grid container spacing={5} className={classes.mainGrid}>
                <Main title={"Bem vindo ao Mu FantasY"} posts={[]} />
                <LoadingSpinner<ServerStat>
                    onSuccess={(data) => {
                        return (
                            <Sidebar
                                status={data}
                                description={sidebar.description}
                                archives={sidebar.archives}
                                social={sidebar.social}
                            />
                        )
                    }}
                    promise={loadStatus}
                />
            </Grid>
        </React.Fragment>
    )
}


export default Home;