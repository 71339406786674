
import MomentUtils from '@date-io/moment';
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Container, createMuiTheme, ThemeProvider } from '@material-ui/core';
import { green, red } from '@material-ui/core/colors';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiPickersUtilsProvider } from '@material-ui/pickers/MuiPickersUtilsProvider';
import 'fontsource-roboto'; /**Must be imported (entry-point) */
import { SnackbarProvider } from 'notistack';
import React, { useState } from 'react';
import {
  BrowserRouter as Router,
  Route, Switch
} from "react-router-dom";
import './App.css';
import DownloadDialog from './components/DownloadModal';
import Header from './components/Header';
import LoginModal from './components/LoginModal';
import RecoveryModal from './components/RecoveryAccountPassword';
import RegistryModal from './components/RegistryModal';
import Account from './newView/Account/Account';
import Home from './newView/Home';
import Ranking from './newView/Ranking/Ranking';
import Onlines from './newView/Server/Onlines';
import { AccountContextProvider } from './utils/AccountContext';
import { DialogProvider } from './utils/Dialog';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: red[800],
    },
    secondary: {
      main: green[500],
    },
    type: 'dark',
    background: {
      default: 'rgb(0,0,0)'
    }
  },
});

const classicTheme = createMuiTheme({
  palette: {
    primary: {
      main: red[800],
    },
    secondary: {
      main: green[500],
    },
    type: 'dark',
    background: {
      default: 'rgb(0,0,0)'
    }
  },
});

function App() {
  const [showRegModal, setShowRegModal] = useState(false)
  const [showLoginModal, setShowLoginModal] = useState(false)
  const [showDownloadModal, setShowDownloadModal] = useState<boolean>(false)
  const [showRecAccount, setShowRecAccount] = useState<boolean>(false)

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <SnackbarProvider maxSnack={3} autoHideDuration={2000} anchorOrigin={{ horizontal: "right", vertical: "bottom" }}>
            <DialogProvider>
              <AccountContextProvider>
                <React.Fragment>
                  <CssBaseline />
                  <Container maxWidth="lg">
                    <Header title="MU FantasY"
                      onClickRegistry={() => setShowRegModal(true)}
                      onClickLogin={() => setShowLoginModal(true)}
                      onClickDownload={() => setShowDownloadModal(true)} />
                    <RegistryModal
                      onClose={() => setShowRegModal(false)}
                      open={showRegModal}
                    />
                    <RecoveryModal
                      onClose={() => setShowRecAccount(false)}
                      open={showRecAccount}
                    />
                    <DownloadDialog open={showDownloadModal} onClose={() => setShowDownloadModal(false)} />
                    <LoginModal
                      open={showLoginModal}
                      onClose={() => setShowLoginModal(false)}
                      onLostPassword={()=> setShowRecAccount(true)}
                    />
                    {/* A <Switch> looks through its children <Route>s and
                    renders the first one that matches the current URL. */}
                    <Switch>
                      <Route path="/account">
                        <Account />
                      </Route>
                      <Route path="/ranking">
                        <Ranking />
                      </Route>
                      <Route path="/onlines">
                        <Onlines />
                      </Route>
                      <Route path="/">
                        <Home />
                      </Route>
                    </Switch>
                  </Container>
                </React.Fragment>
              </AccountContextProvider>
            </DialogProvider>
          </SnackbarProvider>
        </MuiPickersUtilsProvider>
      </Router>
    </ThemeProvider>
  );
}

export default App;
