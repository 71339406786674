/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from 'react'
import { ResponseDefault, ItemShop, AccountInfo, AdminVendaItem, ResponseShop, BuyItemParams } from '../../config/types';
import LoadingSpinner from '../../components/LoadingSpinner';
import { sendRequest } from '../../config/Api';
import { Box, Button, createStyles, FormControl, FormHelperText, Grid, InputLabel, makeStyles, NativeSelect, TextField, Theme, Typography } from '@material-ui/core';
import { useDialog } from '../../utils/Dialog';

type Props = {
    items: ItemShop[],
    accountInfo: AccountInfo
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            margin: theme.spacing(1),
            minWidth: 120,
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
    }),
);

const AdminVendaShop: React.FC<Props> = ({ items, accountInfo }: Props) => {
    const [category, setCategory] = useState<string>();
    const categories = Array.from(new Set(items.map(item => item.category)));
    const [selectedItem, setSelectedItem] = useState<ItemShop>();
    const [valorPago, setValorPago] = useState<number>();
    const [conta, setConta] = useState<string>();
    const classes = useStyles();
    const dialog = useDialog();

    useEffect(() => {
        if (!category && categories.length > 0)
            setCategory(categories[0])
    }, [])

    const handleSell = useCallback(async () => {
        try {
            dialog.showLoading('Processando')
            const body: BuyItemParams = {
                wingIncreaseHp: false
            }
            const response = await sendRequest(`shop/sell-item/${selectedItem!.number}/${valorPago || 0}/${conta!}`)
            const data = await response.json() as ResponseDefault<ResponseShop>
            if (data.error || data.status !== 'SUCCESS')
                dialog.showAlertDialog({ message: data.error || 'Resposta inesperada do servidor', title: 'Atenção' })
            else
                dialog.showAlertDialog({ message: 'Operação realizada com sucesso!', title: 'Atenção' })
        } catch (error) {
            console.log(error)
            dialog.showAlertDialog({ message: 'Erro interno', title: 'Atenção' })
        } finally {
            dialog.closeLoading()
        }
    }, [dialog, selectedItem, valorPago, conta])

    const sellDialog = useCallback(() => {
        dialog.showAlertDialog({
            message: `Deseja adicionar o item? Se o item for premiação lembre de colocar o valor zero.`,
            title: 'Confirmar ação',
            buttons: [
                { text: "CANCELAR" },
                { text: "SIM", action: ()=> handleSell() }
            ]
        })
    }, [dialog, handleSell])

    return (
        <div >
            <Grid container>
                <Grid item md={12}>
                    <FormControl className={classes.formControl}>
                        <InputLabel htmlFor="age-native-helper">Categoria</InputLabel>
                        <NativeSelect
                            onChange={(event) => {
                                setCategory(event.target.value)
                                setSelectedItem(undefined)
                                setValorPago(undefined)
                            }}
                            inputProps={{
                                name: 'age',
                                id: 'age-native-helper',
                            }}
                            value={category}
                        >
                            <option aria-label="None" value="" />
                            {
                                categories.map((categorie, index) => {
                                    return <option value={categorie}>{categorie}</option>
                                })
                            }
                        </NativeSelect>
                        <FormHelperText>Selecione a categoria do item</FormHelperText>
                    </FormControl>
                    <FormControl className={classes.formControl}>
                        <InputLabel htmlFor="age-native-helper">Item</InputLabel>
                        <NativeSelect
                            onChange={(event) => {
                                const item = items.find(item => String(item.number) === event.target.value)
                                setSelectedItem(item)
                                setValorPago(item?.price)
                            }}
                            value={selectedItem?.number}
                            inputProps={{
                                name: 'age',
                                id: 'age-native-helper',
                            }}
                        >
                            <option aria-label="None" value="" />
                            {
                                items.filter(item => item.category === category).map((item, index) => {
                                    return <option value={item.number}>{item.itemName}</option>
                                })
                            }
                        </NativeSelect>
                        <FormHelperText>Selecione o item</FormHelperText>
                    </FormControl>
                    <FormControl className={classes.formControl}>
                        <TextField
                            id="standard-number"
                            type="number"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            rowsMax={1}
                            onChange={(event) => {
                                const valor = Number(event.target.value)
                                setValorPago(valor)
                            }}
                            value={valorPago}
                            label={"Valor*"}
                        />
                    </FormControl>
                    <FormControl className={classes.formControl}>
                        <TextField
                            id="standard-search"
                            label="Conta de destino"
                            type="search"
                            onChange={(event) => { setConta(event.target.value) }}
                            value={conta}
                        />
                    </FormControl>
                </Grid>
                <Grid item>
                    {selectedItem && (
                        <Box>
                            <Typography>{selectedItem.itemName}</Typography>
                            <Box component="div">
                                <img src={`http://www.mufantasy.com.br/shop/${selectedItem.photoItem}`} alt="..." />
                            </Box>
                            <Box>
                                <Button variant="outlined" color="default" onClick={sellDialog}>{"Confirmar"}</Button>
                            </Box>
                        </Box>
                    )}
                </Grid>
            </Grid>
            {/* <div className="col">
                <label>Categoria</label>
                <div className="row-8">
                    <select
                        onChange={(event) => {
                            setCategory(event.target.value)
                            setSelectedItem(undefined)
                            setValorPago(undefined)
                        }}
                        value={category}
                        className="custom-select"
                        id="inputGroupSelect01">
                        <option selected disabled>-</option>
                        {
                            categories.map((categorie, index) => {
                                return <option value={categorie}>{categorie}</option>
                            })
                        }
                    </select>
                </div>
                {category &&
                    <div className="row-8">
                        <label>Item</label>
                        <select
                            onChange={(event) => {
                                const item = items.find(item => String(item.number) === event.target.value)
                                setSelectedItem(item)
                                setValorPago(item?.price)
                            }}
                            value={selectedItem?.number}
                            className="custom-select w-100"
                            id="inputGroupSelect02">
                            <option selected disabled>-</option>
                            {
                                items.filter(item => item.category === category).map((item, index) => {
                                    return <option value={item.number}>{item.itemName}</option>
                                })
                            }
                        </select>
                    </div>
                }
                <div className="row-8">
                    <label htmlFor="exampleInputPassword1">Valor pago</label>
                    <input maxLength={4} value={valorPago} onChange={(event) => {
                        const valor = Number(event.target.value)
                        setValorPago(valor)
                    }} type="number" className="form-control" id="valorInput" />
                </div>
                <div className="row-8">
                    <label htmlFor="exampleInputPassword1">Conta</label>
                    <input maxLength={10} value={conta} onChange={(event) => { setConta(event.target.value) }} className="form-control" id="contaInput" />
                </div>
                {
                    (selectedItem && conta) &&
                    <div className="row-8 mt-1">
                        <button onClick={gravar} className="btn btn-primary">{"Gravar"}</button>
                    </div>
                }
            </div> 
            <div className="col">
                {selectedItem &&
                    <div className="row">
                        <div className={"text-center p-2"}>
                            <h6 className="text-warning">{selectedItem.itemName}</h6>
                            <img src={`http://147.135.123.78/shop/${selectedItem.photoItem}`} className="img-responsive center-block" alt="..." />
                        </div>
                    </div>
                }
            </div>
            */}
        </div>
    )
}

export default AdminVendaShop