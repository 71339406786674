import { Backdrop, Box, Button, Container, createStyles, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, LinearProgress, makeStyles, styled, TextField, Theme, Typography } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React, { createContext, useCallback, useContext, useMemo, useState } from 'react'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.modal + 5,
      color: '#fff',
    },
  }),
);

const StyledLinearProgress = styled(LinearProgress)({
  height: props => "10px",
  width: props => "100%"
})

type InputDialogParam = {
  /**
   * Título do dialogo
   */
  title: string,
  /**
   * Mensagem que será exibida acima do input
   */
  message: string,
  /**
   * Botões de ações do dialogo
   */
  buttons: {
    /**
     * Texto do botão de ação
     */
    text: string,
    /**
     * (Opcional) Callback to botão
     */
    action?: (value: string) => void,
    /**
     * Define se é obrigatório preencher o campo de texto para habilitar o botão
     */
    required?: true
  }[],
  /**
   * (Opcional) Seta algumas propriedades de input
   */
  inputProps?: {
    /**
     * (Opcional) Define se o campo de texto é multilinha
     */
    multiline?: true
    /**
     * (Opcional) Define o número de linhas a ser exibido
     */
    rowsMax?: number
    /**
     * (Opcional) Placeholder do input
     */
    placeHolder?: string,
    /**
     * Define o limite de caracteres do input
     */
    maxLength: number,
    /**
     * Define o tipo do input de texto
     */
    inputType?: 'text' | 'password'
  }
}

type LoadingParam = {
  /**
   * (Opcional) Exibe mensagem de status no loading
   */
  message?: string
}

type AlertDialogParam = {
  /**
   * Mensagem de título do diálogo
   */
  title: string,
  /**
   * Mensagem de corpo do diálogo
   */
  message: string,
  /**
   * (Opcional) Define os botões de ações do diálogo.
   */
  buttons?: {
    /**
     * Texto do botão
     */
    text: string,
    /**
     * (Opcional) Callback de ação do botão
     */
    action?: () => void
  }[]
}

/**
 * Variações de snackbar
 */
type VariantType = 'default' | 'error' | 'success' | 'warning' | 'info';

type DialogProviderContext = {
  /**
   * Exibe diálogo com TextInput
   * @param dialog objeto do tipo `InputDialogParam` 
   */
  showInputDialog: (dialog: InputDialogParam) => void
  /**
   * Exibe barra de loading com opção de mensagem
   * @param message Mensagem de texto do loading
   */
  showLoading: (message?: string) => void
  /**
   * Exibe diálogo de alerta com ações
   * @param dialogo objeto do tipo `AlertDialogParam`
   */
  showAlertDialog: (dialog: AlertDialogParam) => void
  /**
   * Exibe Snackbar lateral
   * @param message Mensagem de alerta
   * @param variant (Opcional) tipo de alerta `VariantType`.
   * @param persist (Opcional) Define se a Snackbar deve permanecer na tela até o usuário fechar
   */
  showSnackbar: (message: string, variant?: VariantType, persist?: boolean) => void
  closeLoading: () => void
}

const DialogContext = createContext<DialogProviderContext | null>(null);

type DialogProviderProps = {
  children: JSX.Element
}

const DialogProvider: React.FC<DialogProviderProps> = ({ children }) => {
  const classes = useStyles()
  const [inputDialog, setInputDialog] = useState<InputDialogParam | null>()
  const [textInputDialog, setTextInputDialog] = useState("")
  const [loading, setLoading] = useState<LoadingParam | null>()
  const [alertDialog, setAlertDialog] = useState<AlertDialogParam | null>()
  const { enqueueSnackbar } = useSnackbar()

  const showInputDialog = useCallback((dialog: InputDialogParam) => {
    setInputDialog(dialog)
  }, [])

  const showLoading = useCallback(( message?: string) => {
    setLoading({ message })
  }, [setLoading])

  const showSnackbar = useCallback((message: string, variant?: VariantType, persist?: boolean) => {
    if (enqueueSnackbar)
      enqueueSnackbar(message, { variant: variant || 'default', persist: persist })
    else
      console.error("showSnackBar must be used within SnackbarProvider")
  }, [enqueueSnackbar])

  const showAlertDialog = useCallback((dialog: AlertDialogParam) => {
    setAlertDialog(dialog)
  }, [setAlertDialog])

  const closeLoading = useCallback(() => {
    setLoading(null)
  }, [setLoading])

  const value = useMemo(() => ({ showInputDialog, showLoading, showSnackbar, showAlertDialog, closeLoading }),
    [showInputDialog, showLoading, showSnackbar, showAlertDialog, closeLoading])

  const validateTextInputDialog = useCallback(() => {
    return textInputDialog != null && textInputDialog.trim().length > 0
  }, [textInputDialog])

  return (
    <DialogContext.Provider value={value}>
      <Backdrop className={classes.backdrop} open={loading != null}>
        <Container component={"div"} maxWidth="md">
          <Box display="flex" flexDirection="column" alignItems="center" bgcolor="rgba(50,50,50,0.9)" p={5} borderRadius={5}>
            {loading?.message && <Typography color={"primary"} style={{fontWeight: 'bold'}}>{loading?.message}</Typography>}
            <StyledLinearProgress color="primary" variant="indeterminate" />
          </Box>
        </Container>
      </Backdrop>
      {/** InputDialog */}
      <Dialog
        open={inputDialog != null}
        onClose={() => setInputDialog(undefined)}
        aria-labelledby="input-dialog-title"
        aria-describedby="input-dialog-description"
      >
        <DialogTitle id="input-dialog-title">{inputDialog?.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="input-dialog-description">
            {inputDialog?.message}
          </DialogContentText>
          <TextField
            id="standard-multiline-static"
            placeholder={(inputDialog && (inputDialog.inputProps?.placeHolder)) || undefined }
            type={inputDialog?.inputProps?.inputType || 'text'}
            multiline={(inputDialog && inputDialog.inputProps?.multiline) || undefined}
            rowsMax={(inputDialog && inputDialog.inputProps?.rowsMax)|| 1}
            value={textInputDialog}
            onChange={(event) => {
              if (event.target.value != null)
                setTextInputDialog(event.target.value)
            }}
            fullWidth
            inputProps={{ maxLength: (inputDialog?.inputProps?.maxLength) }}
          />
        </DialogContent>
        <DialogActions>
          {inputDialog && inputDialog.buttons && inputDialog.buttons.map((button, index) => <Button
            key={index}
            onClick={(event) => {
              if (button.action)
                button.action(textInputDialog)
              setInputDialog(null)
              setTextInputDialog('')
            }}
            color="primary"
            disabled={button.required && !validateTextInputDialog()}
          >
            {button.text}
          </Button>
          )}
        </DialogActions>
      </Dialog>
      {/** AlertDialog */}
      <Dialog
        open={alertDialog != null}
        onClose={() => setAlertDialog(null)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{alertDialog?.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {alertDialog?.message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {alertDialog && alertDialog.buttons && alertDialog.buttons.map((button, index) => <Button
            key={index}
            onClick={(event) => {
              if (button.action)
                button.action()
              setAlertDialog(null)
            }}
            color="primary"
          >
            {button.text}
          </Button>
          )}
          {
            alertDialog && !alertDialog.buttons &&
            <Button onClick={(event) => setAlertDialog(null)} color="primary">OK</Button>
          }
        </DialogActions>
      </Dialog>
      {children}
    </DialogContext.Provider>
  )
}

function useDialog(): DialogProviderContext {
  const context = useContext(DialogContext);

  if (!context) {
    throw new Error(`useInputDialog must be used within a DialogProvider`);
  }

  return context;
}

export { DialogProvider, useDialog }