import { Box, Button, Container, Typography } from '@material-ui/core';
import moment from 'moment';
import React, { useCallback, useState } from 'react';
import LoadingSpinner from '../../components/LoadingSpinner';
import { sendGetRequest, sendRequest } from '../../config/Api';
import { ItemLocation, ItemPurchased, ItemShop, ResponseDefault } from '../../config/types';
import { useAccount } from '../../utils/AccountContext';
import { useDialog } from '../../utils/Dialog';

type ShopPurchasesProps = {
    itemsShop: ItemShop[],
    myItems: ItemPurchased[]
}

const ShopPurchases: React.FC<ShopPurchasesProps> = ({ itemsShop, myItems }: ShopPurchasesProps) => {
    const [items, setItems] = useState<ItemPurchased[]>(myItems)
    const dialog = useDialog()
    const account = useAccount()

    const sellItem = useCallback(async (item: ItemPurchased) => {
        dialog.showLoading('Finalizando troca')
        try {
            const response = await sendRequest(`shop/trade/${item.number}`)
            const data = await response.json()
            if (data.status === "SUCCESS") {
                dialog.showSnackbar('Troca de item realizada com sucesso!', 'success')
                const itemsFiltered = items.filter(i => i.number !== item.number)
                setItems(itemsFiltered)
                account.loadAccountInfo()
            } else {
                dialog.showSnackbar(data.error || 'Erro desconhecido, tente novamente mais tarde', 'warning')
            }
        } catch (error) {
            console.log(error)
            dialog.showSnackbar('Erro desconhecido, tente novamente mais tarde', 'error')
        } finally {
            dialog.closeLoading()
        }
    }, [setItems, items, dialog, account])

    const sellItemDialog = useCallback((item: ItemPurchased) => {
        dialog.showAlertDialog({
            message: `Deseja trocar o item? Para efetivar a troca é necessário que o item esteja no baú ativo da conta. O valor da troca é 70% do valor pago pelo item.`,
            title: 'Confirmação de troca',
            buttons: [
                { text: "CANCELAR" },
                { text: "TROCAR ITEM", action: () => sellItem(item) }
            ]
        })
    }, [dialog, sellItem])

    return (
        <React.Fragment>
            <Container maxWidth={false} component="div" style={{ backgroundColor: 'transparent' }}>
                <Box component="div" width="100%" display="flex" flexDirection="column">
                    {
                        items.map(myItem => {
                            const item = itemsShop.find(item => item.number === myItem.itemNumber);
                            const day = moment(myItem.day, 'YYYY-MM-DD HH:mm:ss.SSS').toDate();
                            //console.log('day', day)
                            return (
                                <Box component="div" flex={1} display="flex" flexDirection="row" width={'100%'} p={5}>
                                    <Box component="div" flex={1} display="flex" justifyContent="space-around" alignItems="center">
                                        <img alt="" src={`http://www.mufantasy.com.br/shop/${item?.photoItem}`}></img>
                                    </Box>
                                    <Box component="div" flex={1} alignItems="center" flexDirection="row" >
                                        <Box>
                                            <Typography
                                                component="span"
                                                variant="body2"
                                                style={{ display: 'inline' }}
                                                color="textPrimary"
                                            >
                                                {moment(day).format('DD/MM/YYYY')}
                                            </Typography>
                                            {` - ${myItem.serial}`}
                                            <Typography>
                                                {`Preço: ${myItem.price}`}
                                            </Typography>
                                            <LoadingSpinner<ResponseDefault<ItemLocation>>
                                                promise={async () => {
                                                    const response = await sendGetRequest('shop/find-item/' + myItem.serial);
                                                    return await response.json()
                                                }}
                                                onSuccess={(json) => {
                                                    return (
                                                        <Box>
                                                            <Typography className="card-text">Localização:
                                                            {json.response.characterName != null && ` Inventário de ${json.response.characterName}`}
                                                                {json.response.accountID != null && ` Bau de ${json.response.accountID}`}
                                                                {json.response.accountID == null && json.response.characterName == null && ` Não encontrado`}
                                                            </Typography>
                                                            {myItem.price > 0 && json.response.accountID != null && (
                                                                <Box>
                                                                    <Button variant="outlined" color="primary" onClick={() => { sellItemDialog(myItem) }}>{"Trocar item"}</Button>
                                                                </Box>
                                                            )}
                                                        </Box>
                                                    )
                                                }}
                                            />
                                        </Box>
                                    </Box>
                                    <Box component="div" flex={1}>

                                    </Box>
                                </Box>
                            )
                        })
                    }
                </Box>
            </Container>
        </React.Fragment>
    )
}

export default ShopPurchases;