import React, { createContext, useCallback, useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';
import { sendGetRequest, sendRequest } from '../config/Api';
import { AccountInfo } from '../config/types';
import { useDialog } from './Dialog';

type AccountContextProps = {
    token?: string | null
    handleLogin: (login: string, password: string, onSuccess: ()=>void) => Promise<void>
    handleLogoff: () => void
    children?: JSX.Element
    loadAccountInfo: ()=>Promise<AccountInfo|null>
    accountInfo?: AccountInfo
    userName?: string
}

type ProviderProps = {
    
}

const AccountContext = createContext<AccountContextProps | null>(null);


const AccountContextProvider: React.FC<ProviderProps> = (props) => {
    const [token, setToken] = useState<string | null>(localStorage.getItem('Authorization'))
    const dialog = useDialog()
    const [accountInfo, setAccountInfo] = useState<AccountInfo>()
    const history = useHistory()
    const [user, setUser] = useState('')
    
    const handleLogoff = useCallback(() => {
        setToken(null)
        setAccountInfo(undefined)
        setUser('')
        localStorage.removeItem('Authorization')
        history.push('/')
    }, [setToken,setAccountInfo, history])

    const loadAccountInfo = useCallback(async ()=>{
        try {
            dialog.showLoading('Carregando dados da conta')
            if (token != null) {
                const response: Response = await sendGetRequest('accounts/account-status')
                if (response.status === 200) {
                    const json = await response.json()
                    const data: AccountInfo = (json.response)
                    setAccountInfo(data)
                    return data
                }
            }
        } catch (error) {
            console.log(error)
        }finally{
            dialog.closeLoading()
        }
        return null
    }, [token, setAccountInfo, dialog])

    const handleToken = useCallback(async ()=>{
        try {
            if (token != null) {
                const response: Response = await sendGetRequest('accounts/check-status')
                const json = await response.json()
                if (response.status === 200 && json.response === "Authorized") {
                    setToken(localStorage.getItem('Authorization')!)
                    loadAccountInfo()
                } else {
                    dialog.showSnackbar('Sessão expirada, autentique novamente!', 'info')
                    setToken(null)
                }
            }
        } catch (error) {
            dialog.showSnackbar('Erro de autenticação, tente novamente', 'error')
        }
    }, [token, setToken, dialog, loadAccountInfo])

    const handleLogin = useCallback(async (login:string, password:string, onSuccess: ()=>void)=>{
        try {
            dialog.showLoading("Autenticando usuário")
            const body = {
                username: login,
                password
            }
            const response: Response = await sendRequest('auth/signin', body)
            if (response.status === 200) {
                const json = (await response.json()).response;
                if (json && json.accessToken) {
                    const accessToken = `Bearer ${json.accessToken}`
                    localStorage.setItem('Authorization', accessToken)
                    setToken(accessToken)
                    dialog.showSnackbar('Login realizado com sucesso!', 'success')
                    onSuccess()//Callback
                    loadAccountInfo()
                    setUser(login)
                    history.push('/account')
                } else
                    dialog.showSnackbar('Erro: login ou senha incorretos', 'warning')
            } else {
                dialog.showSnackbar('Erro: login ou senha incorretos', 'warning')
            }
        } catch (Exception) {
            console.log(Exception)
            dialog.showSnackbar('Erro: login ou senha incorretos', 'error')
        }finally{
            dialog.closeLoading()
        }
    }, [setToken, dialog, loadAccountInfo, history])

    useEffect(() => {
        if(token)
            handleToken()
            //localStorage.setItem('Authorization', token)
    }, [token, handleToken])

    

    return (
        <AccountContext.Provider value={{ token, handleLogin, handleLogoff, accountInfo, loadAccountInfo, userName: user }}>
            {props.children}
        </AccountContext.Provider>
    )
}

const useAccount = () => {
    const context = useContext(AccountContext)
    if (!context)
        throw new Error(`useAccount must be used within a AccountContextProvider`);
    return context
}

export { AccountContextProvider, useAccount }