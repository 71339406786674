/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { AccountInfo, ResponseDefault, Onlines, ItemShop } from '../../config/types';
import { sendGetRequest, sendRequest } from '../../config/Api';
import LoadingSpinner from '../../components/LoadingSpinner';
import AdminVendaShop from '../Admin/AdminVendaShop';
import { Box, Button, Grid, Menu, MenuItem } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';

type Props = {
    accountInfo: AccountInfo
}

type Screens = 'Onlines' | 'VendaShop' | 'Personagem'

const Admin = (props: Props) => {
    const accountInfo = props.accountInfo;
    const [showTab, setShowTab] = useState<'Onlines' | 'VendaShop' | 'Personagem'>('Onlines');
    const [loading, setLoading] = useState<boolean>(true);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const init = async () => {
        setLoading(false);
    }

    useEffect(() => {
        init()
    }, [])

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const renderOnlines = () => {
        return <LoadingSpinner<ResponseDefault<Onlines>>
            promise={async () => {
                const response = await sendGetRequest('admins/online');
                return await response.json()
            }}
            onSuccess={(result) => {
                const onlines = result.response
                return (
                    <div className="d-flex row">
                        <div className="table-responsive">
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Personagem</th>
                                        <th scope="col">Mapa</th>
                                        <th scope="col">Level</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {onlines &&
                                        onlines.map((row, index) => {
                                            return (
                                                <tr key={`dataRow${index}`}>
                                                    <th scope="row">{index + 1}</th>
                                                    <th>{row.name}</th>
                                                    <th>{row.mapNumber}</th>
                                                    <th>
                                                        {row.level}
                                                    </th>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                )
            }}
        />
    }

    return (
        <Grid container>
            <Grid item>
                <Box>
                    <Box>
                        <Button startIcon={<MenuIcon />} color={'default'} aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>Menu</Button>
                        <Menu
                            id="simple-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={() => { }}
                        >
                            <MenuItem
                                onClick={() => {
                                    setShowTab('Onlines')
                                    handleClose()
                                }}
                            >
                                {"Onlines"}
                            </MenuItem>
                            <MenuItem
                                onClick={() => {
                                    setShowTab('VendaShop')
                                    handleClose()
                                }}
                            >
                                {"Item shop"}
                            </MenuItem>
                        </Menu>
                    </Box>
                    <Box>
                        {showTab === 'Onlines' && renderOnlines()}
                        {showTab === 'VendaShop' && <LoadingSpinner<ResponseDefault<ItemShop[]>>
                            promise={async () => {
                                const response = await sendGetRequest('shop/items');
                                return await response.json()
                            }}
                            onSuccess={(result) =>
                                <AdminVendaShop accountInfo={accountInfo} items={result.response.filter(item => Number(item.insertShop) === -1)} />
                            }
                        />}
                    </Box>
                </Box>
            </Grid>
        </Grid>
    );
}

export default Admin;