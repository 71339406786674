import { Box, Menu, MenuItem } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { AccountCircle } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useAccount } from '../utils/AccountContext';

const useStyles = makeStyles((theme) => ({
  toolbar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    width: '100%'
  },
  toolbarTitle: {
    flex: 1,
  },
  toolbarSecondary: {
    justifyContent: 'space-between',
    overflowX: 'auto',
  },
  toolbarLink: {
    padding: theme.spacing(1),
    flexShrink: 0,
  },
}));

type HeaderProps = {
  title: string,
  onClickRegistry: () => void
  onClickLogin: () => void
  onClickDownload: () => void
}

export default function Header(props: HeaderProps) {
  const classes = useStyles();
  const { onClickRegistry, onClickLogin } = props;
  const history = useHistory();
  const account = useAccount();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <Toolbar className={classes.toolbar}>
        <Button size="small" onClick={onClickRegistry}>Registrar</Button>
        {/* <Typography
          component="h2"
          variant="h5"
          color="inherit"
          align="center"
          noWrap
          className={classes.toolbarTitle}
        >
          {title}
        </Typography> */}
        <Box component="div" flex={1} display="flex" alignItems="center" flexDirection="column">
          <img src={"logo-mini.png"} alt="minilogo"/>
        </Box>
        {account.token ?
          <React.Fragment>
            {/* <Badge badgeContent={0} color="primary">
              <Notifications />
            </Badge> */}
            <IconButton onClick={handleClick} aria-controls="simple-menu" aria-haspopup="true">
              <AccountCircle />
              <Typography>{account.accountInfo?.info.membId || account.userName}</Typography>
            </IconButton >
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={() => {
                handleClose()
                history.push('/account')
              }}>Minha conta</MenuItem>
              <MenuItem onClick={() => {
                handleClose()
                account.handleLogoff()
              }}>Sair</MenuItem>
            </Menu>
          </React.Fragment>
          :
          <React.Fragment>
            <Button variant="outlined" size="small" onClick={onClickLogin}>
              {"Login"}
            </Button>
          </React.Fragment>
        }
      </Toolbar>
      <Toolbar component="nav" variant="dense" className={classes.toolbarSecondary}>
        <React.Fragment>
          <Button onClick={() => history.push("/")}>{"Home"}</Button>
          <Button onClick={props.onClickDownload}>{"Downloads"}</Button>
          {/* <Button onClick={() => window.open("http://muoldfriends.com/forum/")}>{"Forum"}</Button> */}
          <Button onClick={() => history.push("/ranking")}>{"Ranking"}</Button>
          <Button onClick={() => history.push("/onlines")}>{"Onlines"}</Button>
        </React.Fragment>
      </Toolbar>
    </React.Fragment>
  );
}

Header.propTypes = {
  sections: PropTypes.array,
  title: PropTypes.string,
};