import { Divider, Link } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';

const styles = (theme: Theme) =>
    createStyles({
        root: {
            margin: 0,
            padding: theme.spacing(2),
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        },
    });

export interface DialogTitleProps extends WithStyles<typeof styles> {
    id: string;
    children: React.ReactNode;
    onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme: Theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

// const DialogActions = withStyles((theme: Theme) => ({
//   root: {
//     margin: 0,
//     padding: theme.spacing(1),
//   },
// }))(MuiDialogActions);

export default function DownloadDialog({ open, onClose }: { open: boolean, onClose: () => void }) {

    return (
        <div>
            <Dialog onClose={onClose} aria-labelledby="customized-dialog-title" open={open} maxWidth={'md'}>
                <DialogTitle id="customized-dialog-title" onClose={onClose}>Downloads</DialogTitle>
                <DialogContent dividers>
                    <Typography variant="h6">Instalador executável:</Typography>
                    <Link
                        //href={"https://www.mediafire.com/file/uwb0ioi55i7op51/MF_Instalador_2022.exe/file"}
                        href={"https://www.mediafire.com/file/v7alpykzryowavf/mf_cliente.exe/file"}
                    >
                        {"Clique aqui para baixar"}
                    </Link>
                    <Typography gutterBottom>Intruções: Execute o instalador e escolha o local onde o jogo será instalado, por fim clique em 'Baixar e Instalar'.</Typography>
                    {/* <Divider />
                    <Typography variant="h6">Launcher básico:</Typography>
                    <Link
                        href={"http://muoldfriends.com/download/Launcher.zip"}
                    >
                        {"Clique aqui para baixar"}
                    </Link>
                    <Typography gutterBottom>Intruções: Extraia todos os arquivos do arquivo ZIP em uma pasta e execute o arquivo MU Launcher.exe, o launcher irá atualizar e por fim você deve baixar o conteúdo adicional em Opções.</Typography> */}
                </DialogContent>
            </Dialog>
        </div>
    );
}