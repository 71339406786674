/* eslint-disable jsx-a11y/anchor-is-valid */
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import React from "react";
import Forum from "../newView/Forum";

type MainProps = {
  posts: string[];
  title: string;
};

export default function Main(props: MainProps) {
  const { title } = props;

  return (
    <Grid item xs={12} md={8}>
      <Typography variant="h6" gutterBottom>
        {title}
      </Typography>
      <Divider />
      <React.Fragment>
        {/* <h3>Bem vindo ao MU FantasY!</h3> */}
        <p>
          Conheça o verdadeiro significado de ser o mais forte. Por ser um
          Servidor Hard, aqui no mufantasy o que manda é ter a melhor
          distribuição de pontos, opcionais de sets e armas. Não seja apenas
          mais um, seja o mais forte e torne-se imbatível!
        </p>
        <p>
          <em>Faça seu cadastro e comece hoje mesmo.</em>
        </p>
        {/* <p>Nós somos uma comunidade de fãs da versão clássica.</p>
        <p>Se você ainda não tem uma conta crie uma navegando em <strong>Conta</strong>.</p>
        <p>Experiência base do servidor: <em>300</em>.</p>
        <p>Para acompanhar as últimas notícias visite nosso fórum.</p> */}
        <div>
          <Typography variant="h6" gutterBottom>
            {"Notícias e Tutoriais"}
          </Typography>
          <Forum/>
        </div>
      </React.Fragment>
    </Grid>
  );
}

Main.propTypes = {
  posts: PropTypes.array,
  title: PropTypes.string,
};
