import { Button, Container, MobileStepper, useTheme } from '@material-ui/core';
import React, { useState } from 'react';
import { autoPlay } from 'react-swipeable-views-utils';
import SwipeableViews from 'react-swipeable-views';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const Carrousel: React.FC = () => {
    const theme = useTheme();
    const [activeStep, setActiveStep] = useState(0);
    const carretelSteps = [
        { imgPath: 'carrouselBg.jpg' },
        { imgPath: 'carrouselBg2.jpg' },
        { imgPath: 'carrouselBg3.jpg' },
    ];

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStepChange = (step: number) => {
        setActiveStep(step);
    };

    return (
        <Container fixed style={{ width: '100%', height: '80%', maxHeight: '80%' }}>
            <AutoPlaySwipeableViews
                style={{ minHeight: "100%" }}
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={activeStep}
                enableMouseEvents
                onChangeIndex={handleStepChange}
                interval={4000}
            >

                {carretelSteps.map((step, index) => (
                    <Container component={"div"} key={`container-${step.imgPath}`} style={{ width: '100%', height: '100%' }}>
                        {Math.abs(activeStep - index) <= 2 ? (
                            <img src={step.imgPath} alt={""} style={{maxWidth: '100%'}}/>
                        ) : <></>}
                    </Container>
                ))}
            </AutoPlaySwipeableViews>
            <MobileStepper
                style={{ backgroundColor: 'transparent', marginTop: "-10px" }}
                steps={carretelSteps.length}
                position="static"
                variant="dots"
                activeStep={activeStep}
                nextButton={
                    <Button size="small" onClick={handleNext} disabled={activeStep === carretelSteps.length - 1}>
                        {theme.direction === 'rtl' ? <KeyboardArrowLeft fontSize={'small'} /> : <KeyboardArrowRight />}
                    </Button>
                }
                backButton={
                    <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                        {theme.direction === 'rtl' ? <KeyboardArrowRight fontSize={'small'} /> : <KeyboardArrowLeft />}
                    </Button>
                }
            />
        </Container>
    )
}

export default Carrousel;