import { faBuysellads, faCcMastercard, faShopify, faShopware } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, Card, CardActionArea, CardActions, CardContent, createStyles, FormControl, Grid, InputLabel, List, ListItem, ListItemIcon, ListItemText, makeStyles, MenuItem, Select, Theme, Typography, withStyles } from '@material-ui/core';
import { green, pink, purple, red, yellow } from '@material-ui/core/colors';
import ChangeHistoryIcon from '@material-ui/icons/ChangeHistory';
import DetailsIcon from '@material-ui/icons/Details';
import FlareIcon from '@material-ui/icons/Flare';
import SecurityIcon from '@material-ui/icons/Security';
import React, { useEffect, useState } from 'react';
import { ItemShop } from '../../config/types';
import BuyItemDialog from './BuyItemDialog';

type ShopCatalogProps = {
    items: ItemShop[]
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            margin: theme.spacing(1),
            minWidth: 120,
            width: '100%'
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
        root: {
            height: '100%',
            backgroundColor: 'rgba(0,0,0,0.1)',
            position: 'relative',
            width: '100%',
            minWidth: '100%'
        },
        media: {
            height: 140,
        },
        button: {
            margin: theme.spacing(1),
        }
    }),
);

const formatter = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
});

const ColorButton = withStyles((theme: Theme) => ({
    root: {
      color: theme.palette.getContrastText(purple[500]),
      backgroundColor: purple[500],
      '&:hover': {
        backgroundColor: purple[700],
      },
    },
  }))(Button);

const ShopCatalog: React.FC<ShopCatalogProps> = ({ items }) => {
    const classes = useStyles();
    // const [itemList, setItemList] = useState<ItemShop[]>();
    const [category, setCategory] = useState<string>();
    const categories = Array.from(new Set(items.map(item => item.category)));
    const [selectedItem, setSelectedItem] = useState<ItemShop>();

    useEffect(() => {
        if (!category && categories.length > 0)
            setCategory(categories[0])
    }, [category, setCategory, categories])

    return (
        <React.Fragment>
            <FormControl className={classes.formControl}>
                <Box component="div" display="flex" flexDirection="row" width="100%" justifyContent="space-between">
                    <Box component="div" minWidth={120}>
                    <InputLabel id="categorie-simple-select-label">Categoria</InputLabel>
                    <Select
                        labelId="categorie-simple-select-label"
                        id="categorie-simple-select"
                        value={category}
                        onChange={(event) => setCategory(event.target.value as string)}
                        fullWidth
                    >
                        {
                            categories.map((categorie, index) => {
                                return <MenuItem value={categorie}>{categorie}</MenuItem>
                            })
                        }
                    </Select>
                    </Box>
                    {/* <Box component="div">
                        <label htmlFor="icon-button-file">
                        <ColorButton
                            variant="contained"
                            color="default"
                            className={classes.button}
                            onClick={()=>{

                            }}
                            startIcon={<FontAwesomeIcon color={'white'} icon={faCcMastercard} />}
                        >
                            {`Adicionar moedas`}
                        </ColorButton>
                        </label>
                    </Box> */}
                </Box>
            </FormControl>
            <Grid container spacing={2}>
                {
                    items.filter(item => item.category === category)
                        .map(item =>
                            <Grid item xs={12} md={6} lg={4}>
                                <Card className={classes.root}>
                                    <CardActionArea style={{height: '100%'}}>
                                        <Box component="div" display="flex" flexDirection="column" alignItems="center" width="100%">
                                            <Box component="div">
                                                <img src={`http://www.mufantasy.com.br/shop/${item.photoItem}`} alt="..." />
                                            </Box>
                                        </Box>
                                        <CardContent>
                                            <Typography gutterBottom variant="h5" component="h2">
                                                {item.itemName}
                                            </Typography>
                                            <Typography variant="h6" color="primary" component="p">
                                                {formatter.format(item.price)}
                                            </Typography>
                                            <List component="nav" aria-label="main mailbox folders">
                                                {
                                                    item.damageMin && (
                                                        <>
                                                            <ListItem>
                                                                <ListItemIcon>
                                                                    <ChangeHistoryIcon style={{ color: green[500] }} />
                                                                </ListItemIcon>
                                                                <ListItemText primary={`Dano máximo: ${item.damageMax}`} />
                                                            </ListItem>
                                                            <ListItem>
                                                                <ListItemIcon>
                                                                    <DetailsIcon style={{ color: red[500] }} />
                                                                </ListItemIcon>
                                                                <ListItemText primary={`Dano mínino: ${item.damageMin}`} />
                                                            </ListItem>
                                                        </>
                                                    )
                                                }
                                                {
                                                    item.magicDamage && (
                                                        <ListItem>
                                                            <ListItemIcon>
                                                                <FlareIcon style={{ color: yellow[500] }}></FlareIcon>
                                                            </ListItemIcon>
                                                            <ListItemText primary={`Dano mágico: ${item.magicDamage}`} />
                                                        </ListItem>
                                                    )
                                                }
                                                {
                                                    item.defense && (
                                                        <ListItem>
                                                            <ListItemIcon>
                                                                <SecurityIcon style={{ color: pink[500] }}></SecurityIcon>
                                                            </ListItemIcon>
                                                            <ListItemText primary={`Defesa: ${item.defense}`} />
                                                        </ListItem>
                                                    )
                                                }
                                                <ListItem>
                                                    <ListItemText primary={`Classes:`} />
                                                </ListItem>
                                                {item.bk != null && item.bk !== 0 && <ListItem><ListItemText primary={item.bk === 1 ? "Dark Knight" : "Blade Knight"} /></ListItem>}
                                                {item.sm != null && item.sm !== 0 && <ListItem><ListItemText primary={item.sm === 1 ? "Dark Wizard" : "Soul Master"} /></ListItem>}
                                                {item.elf != null && item.elf !== 0 && <ListItem><ListItemText primary={item.elf === 1 ? "Fire Elf" : "Muse Elf"} /></ListItem>}
                                                {item.mg != null && item.mg !== 0 && <ListItem><ListItemText primary={"Magic Gladiator"} /></ListItem>}
                                            </List>
                                        </CardContent>
                                    </CardActionArea>
                                    <CardActions style={{position: 'absolute', bottom:0, width: '100%' }}>
                                        <Box component="div" flex={1} display="flex" flexDirection="column" justifyContent="flex-end" alignItems="flex-end">
                                            <Button size="small" color="primary" variant="contained" onClick={()=>setSelectedItem(item)}>
                                                {"Comprar"}
                                            </Button>
                                        </Box>
                                    </CardActions>
                                </Card>
                            </Grid>
                        )
                }
            </Grid>
            <BuyItemDialog item={selectedItem} onClose={()=>setSelectedItem(undefined)} />
        </React.Fragment>
    )
}

export default ShopCatalog;