import { Checkbox, FormControlLabel, Grid, TextField } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import React, { useState } from 'react';
import { useAccount } from '../utils/AccountContext';

const styles = (theme: Theme) =>
    createStyles({
        root: {
            margin: 0,
            padding: theme.spacing(2),
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        },
    });

export interface DialogTitleProps extends WithStyles<typeof styles> {
    id: string;
    children: React.ReactNode;
    onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme: Theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme: Theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

type LoginModalProps = {
    open: boolean
    onClose: () => void
    onLostPassword: () => void
}

export default function LoginModal({ open, onClose, onLostPassword }: LoginModalProps) {
    const [login, setLogin] = useState<string>("")
    const [senha, setSenha] = useState<string>("")
    const [checked, setChecked] = useState(false)
    const account = useAccount()

    const validar = () => {
        return (login != null && login.trim().length > 0
            && senha != null && senha.trim().length > 0
        )
    }

    return (
        <div>
            <Dialog onClose={onClose} aria-labelledby="customized-dialog-title" open={open}>
                <DialogTitle id="customized-dialog-title" onClose={onClose}>
                    {"Login"}
                </DialogTitle>
                <DialogContent dividers>
                    <Grid container spacing={2}>
                        <Grid item sm={12}>
                            <TextField id="outlined-basic" label="Login" variant="outlined" fullWidth={true} onChange={(event) => { if (login.length < 10) setLogin(event.target.value) }} />
                        </Grid>
                        <Grid item sm={12}>
                            <TextField type="password" fullWidth={true} id="outlined-basic" label="Senha" variant="outlined" onChange={(event) => { setSenha(event.target.value) }} />
                        </Grid>
                        <Grid item sm={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={checked}
                                        onChange={(event) => setChecked(event.target.checked)}
                                        name="checkedB"
                                        color="primary"
                                    />
                                }
                                label="Memorizar senha"
                            />
                        </Grid>
                        <Grid item sm={12}>
                            <Button onClick={() => {
                                onLostPassword()
                                onClose()
                            }}
                                color="default">
                                {"Esqueci minha senha"}
                            </Button>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button disabled={!validar()} onClick={() => account.handleLogin(login, senha, onClose)} autoFocus color="primary">
                        Entrar
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}