import { Box, Checkbox, FormControlLabel, Grid, Step, StepLabel, Stepper, TextField } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import React, { useCallback, useEffect, useState } from 'react';
import { sendRequest } from '../config/Api';
import rawJson from '../regras.json';
import { useDialog } from '../utils/Dialog';

const styles = (theme: Theme) =>
    createStyles({
        root: {
            margin: 0,
            padding: theme.spacing(2),
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        },
    });

export interface DialogTitleProps extends WithStyles<typeof styles> {
    id: string;
    children: React.ReactNode;
    onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme: Theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme: Theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

type RegistryModalProps = {
    open: boolean
    onClose: () => void
}

const steps = ['Dados da conta', 'Confirmação de e-mail', 'Termos de uso']

const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/

export default function RegistryModal({ open, onClose }: RegistryModalProps) {
    const [login, setLogin] = useState<string>("")
    const [senha, setSenha] = useState<string>("")
    const [email, setEmail] = useState<string>("")
    const [senha2, setSenha2] = useState<string>("")
    const [chave, setChave] = useState<string>("")
    const [checked, setChecked] = useState(false)
    const dialog = useDialog()
    const [activeStep, setActiveStep] = React.useState(0)
    const [retryEmail, setRetryEmail] = useState<boolean>(true)

    useEffect(() => {
        if (!open) {
            setLogin("")
            setSenha("")
            setEmail("")
            setSenha2("")
            setChave("")
            setActiveStep(0)
            setRetryEmail(true)
        }
    }, [open])

    const validarFormulario = useCallback(() => {
        return (login != null && login.trim().length > 0
            && email != null && email.trim().length > 0
            && senha != null && senha.trim().length > 3
            && senha === senha2)
    }, [email, login, senha, senha2])

    const validar = useCallback(() => {
        return (validarFormulario()
            && checked
            && chave != null && chave.trim().length >= 5
            && activeStep === 2)
    }, [activeStep, chave, checked, validarFormulario])

    const doCadastro = useCallback(async () => {
        try {
            dialog.showLoading("Cadastrando conta")
            const body = {
                login,
                password: senha,
                email,
                emailCode: chave.trim()
            }
            const response: Response = await sendRequest('accounts/register', body)
            if (response.status === 200) {
                dialog.showSnackbar("Conta cadastrada com sucesso!", "success")
                onClose()
            } else if (response.status !== 200) {
                const json = await response.json()
                console.log(json)
                dialog.showSnackbar(json.error, "warning")
            }
        } catch (exception) {
            // console.log("cadastro> erro > ", Exception)
            dialog.showSnackbar("Ocorreu um erro ao cadastrar, tente novamente!", "error")
        } finally {
            dialog.closeLoading()
        }
    }, [chave, dialog, email, login, onClose, senha])

    const sendEmailCode = useCallback(async () => {
        setRetryEmail(!retryEmail)
        try {
            const response: Response = await sendRequest('auth/validate-email-register', { email })
            if (response.status !== 200) {
                const json = await response.json()
                dialog.showSnackbar(json.error, "warning")
                setRetryEmail(true)
            }
        } catch (exception) {
            dialog.showSnackbar("Ocorreu um erro ao cadastrar, tente novamente!", "error")
        } finally {
        }
    }, [retryEmail, email, dialog])

    const handleNext = useCallback(() => {
        if (activeStep === 0) {
            if (validarFormulario() && emailRegex.test(email))
                sendEmailCode()
            else {
                dialog.showAlertDialog({ message: "Preencha todos os campos corretamente!", title: 'Dado incorretos' })
                return
            }
        }

        if (activeStep === steps.length - 1)
            doCadastro()
        else
            setActiveStep((prevActiveStep) => prevActiveStep + 1);

    }, [activeStep, doCadastro, email, sendEmailCode, dialog, validarFormulario])

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }

    return (
        <div>
            <Dialog onClose={onClose} aria-labelledby="customized-dialog-title" open={open} maxWidth={'sm'} >
                <DialogTitle id="customized-dialog-title" onClose={onClose}>
                    {"Registro de conta"}
                </DialogTitle>
                <Stepper activeStep={activeStep}>
                    {steps.map((label, index) => {
                        const stepProps: { completed?: boolean } = {};
                        const labelProps: { optional?: React.ReactNode } = {};
                        return (
                            <Step key={index} {...stepProps}>
                                <StepLabel {...labelProps}>{label}</StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>
                <DialogContent dividers>
                    <Grid container spacing={2} >

                        {activeStep === 0 && (
                            <>
                                <Grid item sm={12}>
                                    <TextField id="outlined-basic"
                                        label="Login"
                                        variant="outlined"
                                        fullWidth={true}
                                        onChange={(event) => { if (login.length < 10) setLogin(event.target.value) }}
                                        value={login}
                                    />
                                </Grid>
                                <Grid item sm={12}>
                                    <TextField
                                        id="outlined-basic"
                                        label="E-mail"
                                        variant="outlined"
                                        fullWidth={true}
                                        helperText="Atenção! Utilize um e-mail válido, em caso de perda da senha você poderá recuperar pelo email."
                                        onChange={(event) => { setEmail(event.target.value) }}
                                        inputProps={{
                                            inputMode: 'email'
                                        }}
                                        value={email}
                                        error={email.trim().length > 0 && !emailRegex.test(email)}
                                    />
                                </Grid>
                                <Grid item sm={12} md={6}>
                                    <TextField
                                        type="password"
                                        fullWidth={true}
                                        id="outlined-basic"
                                        label="Senha"
                                        variant="outlined"
                                        onChange={(event) => { setSenha(event.target.value) }}
                                        value={senha}
                                    />
                                </Grid>
                                <Grid item sm={12} md={6}>
                                    <TextField
                                        type="password"
                                        fullWidth={true}
                                        id="outlined-basic"
                                        label="Repita sua senha"
                                        variant="outlined"
                                        onChange={(event) => { setSenha2(event.target.value) }}
                                        value={senha2}
                                    />
                                </Grid>
                            </>
                        )}
                        {activeStep === 1 && (
                            <Box component='div' display="flex" flexDirection="column" justifyContent="center" width="100%">
                                <Typography variant="h6" gutterBottom>
                                    {"Chave de segurança"}
                                </Typography>
                                <Typography gutterBottom>
                                    {"Nós enviaremos uma chave de segurança para confirmar se o e-mail informado é válido, verifique sua caixa de entrada e informe a chave recebida."}
                                </Typography>
                                <Typography gutterBottom>
                                    {"Se você não receber o e-mail, verifique a caixa de spam."}
                                </Typography>
                                <TextField
                                    fullWidth={true}
                                    id="outlined-basic"
                                    label="Chave de segurança"
                                    variant="outlined"
                                    onChange={(event) => setChave(event.target.value)}
                                    value={chave}
                                    margin={'normal'}
                                />
                                <Button onClick={() => sendEmailCode()} color="default" disabled={!retryEmail}>
                                    {"Enviar e-mail novamente"}
                                </Button>
                            </Box>
                        )}
                        {activeStep === 2 && (
                            <>
                                {rawJson.topicc.map(topic =>
                                    <Box>
                                        <Typography variant="h6" gutterBottom>
                                            {topic.text}
                                        </Typography>
                                        {
                                            topic.subtopics.map(text =>
                                                <Typography variant="subtitle1" gutterBottom>
                                                    {text}
                                                </Typography>
                                            )
                                        }
                                    </Box>
                                )}
                                <Grid item sm={12}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={checked}
                                                onChange={(event) => setChecked(event.target.checked)}
                                                name="checkedB"
                                                color="primary"
                                            />
                                        }
                                        label="Declaro ter lido as regras do jogo e aceito os termos estabelecidos."
                                    />
                                </Grid>
                            </>
                        )}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Box component={'div'} >
                        <Button disabled={activeStep === 0} onClick={handleBack} color="primary" >
                            {"Voltar"}
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleNext}
                            disabled={(activeStep >= steps.length - 1) && !validar()}
                        >
                            {activeStep === steps.length - 1 ? 'Criar conta' : 'Próximo'}
                        </Button>
                    </Box>
                    {/* <Button disabled={!validar()} onClick={doCadastro} autoFocus color="primary">
                        Criar conta
                    </Button> */}
                </DialogActions>
            </Dialog>
        </div>
    );
}