import { Box, Grid, Step, StepLabel, Stepper, TextField } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import React, { useCallback, useEffect, useState } from 'react';
import { sendRequest } from '../config/Api';
import { useAccount } from '../utils/AccountContext';
import { useDialog } from '../utils/Dialog';

const styles = (theme: Theme) =>
    createStyles({
        root: {
            margin: 0,
            padding: theme.spacing(2),
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        },
    });

export interface DialogTitleProps extends WithStyles<typeof styles> {
    id: string;
    children: React.ReactNode;
    onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme: Theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme: Theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

type LoginModalProps = {
    open: boolean
    onClose: () => void
}

const steps = ['Código de verificação', 'Troca da senha']

export default function RecoveryModal({ open, onClose }: LoginModalProps) {
    const [login, setLogin] = useState<string>("")
    const [senha, setSenha] = useState<string>("")
    const [senha2, setSenha2] = useState<string>("")
    const [code, setCode] = useState<string>("")
    const account = useAccount()
    const [activeStep, setActiveStep] = React.useState(0)
    const dialog = useDialog()
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [retryEmail, setRetryEmail] = useState<boolean>(true)

    const validarSenhas = useCallback(() => {
        return (senha != null && senha.trim().length > 0
            && senha2 != null && senha2.trim().length > 0
            && senha === senha2
        )
    }, [senha, senha2])

    const validarLogin = useCallback(() => {
        return (login != null && login.trim().length > 0)
    }, [login])

    const validar = useCallback(() => {
        return (validarLogin() && validarSenhas())
    }, [validarLogin, validarSenhas])

    useEffect(() => {
        if (!open) {
            setLogin("")
            setSenha("")
            setCode("")
            setActiveStep(0)
        }
    }, [open])

    const changePassword = useCallback(async () => {
        try {
            dialog.showLoading('Alterando a senha da conta')
            const body = {
                newPassword: senha,
                accountId: login,
                emailCode: code
            }
            const response: Response = await sendRequest(`accounts/change-password-recovery`, body)
            if (response.status !== 200) {
                const json = await response.json()
                dialog.showSnackbar(json.error, "warning")
            }else{
                account.handleLogin(login, senha, () => {
                    dialog.showSnackbar("Senha alterada com sucesso!", 'success')
                    onClose()
                })
            }
        } catch (exception) {
            dialog.showSnackbar("Ocorreu um erro ao cadastrar, tente novamente!", "error")
        } finally {
            dialog.closeLoading()
        }
    }, [dialog, senha, login, code, account, onClose])

    const sendEmailCode = useCallback(async () => {
        try {
            dialog.showLoading('Enviando chave para e-mail da conta')
            const response: Response = await sendRequest(`accounts/recover-account/${login}`)
            if (response.status !== 200) {
                const json = await response.json()
                dialog.showSnackbar(json.error, "warning")
            }
        } catch (exception) {
            dialog.showSnackbar("Ocorreu um erro ao cadastrar, tente novamente!", "error")
            setRetryEmail(true)
        } finally {
            setRetryEmail(false)
            dialog.closeLoading()
        }
    }, [login, dialog])

    const handleNext = useCallback(() => {
        if (activeStep === steps.length - 1)
            changePassword()
        else {
            sendEmailCode()
            setActiveStep((prevActiveStep) => prevActiveStep + 1)
        }
    }, [activeStep, sendEmailCode, changePassword])

    const handleBack = useCallback(() => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }, [setActiveStep])

    return (
        <div>
            <Dialog onClose={onClose} aria-labelledby="customized-dialog-title" open={open}>
                <DialogTitle id="customized-dialog-title" onClose={onClose}>
                    {"Recuperar conta"}
                </DialogTitle>
                <Stepper activeStep={activeStep}>
                    {steps.map((label, index) => {
                        const stepProps: { completed?: boolean } = {};
                        const labelProps: { optional?: React.ReactNode } = {};
                        return (
                            <Step key={index} {...stepProps}>
                                <StepLabel {...labelProps}>{label}</StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>
                <DialogContent dividers>
                    {activeStep === 0 && (
                        <Grid item sm={12}>
                            <Box component='div' display="flex" flexDirection="column" justifyContent="center" width="100%">
                                <Typography variant="h6" gutterBottom>
                                    {"Verificação de e-mail"}
                                </Typography>
                                <Typography gutterBottom>
                                    {"Em caso de esquecimento de senha, é necessário criar uma nova senha utilizando uma chave de segurança."}
                                </Typography>
                                <Typography gutterBottom>
                                    {"Nós enviaremos uma chave de segurança para o e-mail registrado na conta informada."}
                                </Typography>
                                <Typography gutterBottom>
                                    {"Se você não receber o e-mail, verifique a caixa de spam."}
                                </Typography>
                                <TextField
                                    id="outlined-basic"
                                    label="Login"
                                    variant="outlined"
                                    fullWidth={true}
                                    onChange={(event) => { if (login.length < 10) setLogin(event.target.value) }}
                                    value={login}
                                    margin="normal"
                                />
                                {/* <Button onClick={() => sendEmailCode()} color="default" disabled={!retryEmail}>
                                    {"Enviar e-mail novamente"}
                                </Button> */}
                            </Box>
                        </Grid>
                    )}
                    {activeStep === 1 && (<Grid container spacing={2}>
                        <Grid item sm={12}>
                            <TextField
                                label="Chave de segurança"
                                variant="outlined"
                                fullWidth={true}
                                onChange={(event) => { if (code.length < 10) setCode(event.target.value) }}
                                value={code}
                                margin="normal"
                            />
                        </Grid>
                        <Grid item sm={12}>
                            <TextField
                                type="password"
                                fullWidth={true}
                                id="outlined-basic"
                                label="Nova senha"
                                variant="outlined"
                                onChange={(event) => { setSenha(event.target.value) }}
                                value={senha}
                                margin="normal"
                                error={!validarSenhas()}
                            />
                        </Grid>
                        <Grid item sm={12}>
                            <TextField
                                type="password"
                                fullWidth={true}
                                id="outlined-basic"
                                label="Repita a nova senha"
                                variant="outlined"
                                onChange={(event) => { setSenha2(event.target.value) }}
                                value={senha2}
                                margin="normal"
                                error={!validarSenhas()}
                            />
                        </Grid>
                    </Grid>
                    )}
                </DialogContent>
                <DialogActions>
                    <Box component={'div'} >
                        <Button disabled={activeStep === 0} onClick={handleBack} color="primary" >
                            {"Voltar"}
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleNext}
                            disabled={((activeStep >= steps.length - 1) && !validar()) || (activeStep === 0 && !validarLogin())}
                        >
                            {activeStep === steps.length - 1 ? 'Alterar senha' : 'Próximo'}
                        </Button>
                    </Box>
                    {/* <Button disabled={!validar()} onClick={doCadastro} autoFocus color="primary">
                        Criar conta
                    </Button> */}
                </DialogActions>
            </Dialog>
        </div>
    );
}