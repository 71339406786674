import { Avatar, Box, FormControl, Grid, IconButton, InputLabel, MenuItem, Select } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import InfoIcon from '@material-ui/icons/Info';
import { KeyboardDatePicker } from '@material-ui/pickers/DatePicker/DatePicker';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { StyledBadge } from '../../components/StyledBadge';
import { sendGetRequest, sendGetRequestWithoutToken } from '../../config/Api';
import { Class, PublicOnlines, RankingData } from '../../config/types';
import { useDialog } from '../../utils/Dialog';


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            //   margin: theme.spacing(1),
            minWidth: 120,
            width: '100%'
        },
        table: {
            minWidth: 300,
            // maxWidth: 300
        },
    }),
);

const Onlines: React.FC = () => {
    const classes = useStyles()
    const [data, setData] = useState<PublicOnlines[]>([])
    const dialog = useDialog()
    const [open, setOpen] = React.useState(false)

    const loadData = useCallback(async () => {
        try {
            dialog.showLoading('Carregando dados do ranking')
            const url = `server/onlines`
            const response = await sendGetRequestWithoutToken(url)
            if (response && response.status === 200) {
                const data = (await response.json()).response as PublicOnlines[];
                console.log(data)
                setData(data)
            }
        } catch (error) {
            //DO NOTHING?
        } finally {
            dialog.closeLoading()
        }
    }, [setData, dialog])

    useEffect(() => {
        loadData();
    }, [loadData]);

    const getAvatar = useCallback((classId: Class) => {
        let avatar = undefined
        switch (Number(classId)) {
            case 0:
                avatar = 'avatarSM.png'
                break;
            case 1:
                avatar = 'avatarSM.png'
                break;
            case 16:
                avatar = 'avatarBK.png'
                break;
            case 17:
                avatar = 'avatarBK.png'
                break;
            case 32:
                avatar = 'avatarELF.png'
                break;
            case 33:
                avatar = 'avatarELF.png'
                break;
            case 48:
                avatar = 'avatarMG.png'
                break;
        }
        return avatar
    }, [])

    return (
        <React.Fragment>
            {/* <Grid container spacing={2}>
                <Grid item md={12} >
                    <label htmlFor="icon-button-file">
                        <IconButton onClick={()=>setOpen(!open)} color="primary" aria-label="upload picture" component="span">
                            <InfoIcon></InfoIcon>
                        </IconButton>
                    </label>
                </Grid>
            </Grid> */}
            <TableContainer component={Paper} >
                <Table className={classes.table} size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell>#</TableCell>
                            <TableCell align="center">Personagem</TableCell>
                            <TableCell align="center">Level</TableCell>
                            <TableCell align="center">Resets</TableCell>
                            <TableCell align="center">Servidor</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((row, index) => (
                            <TableRow key={index}>
                                <TableCell component="th" scope="row">
                                    {index + 1}
                                </TableCell>
                                <TableCell align="center">{row.name}</TableCell>
                                <TableCell align="center">{row.level}</TableCell>
                                <TableCell align="center">{row.resets}</TableCell>
                                <TableCell align="center">{row.serverName}</TableCell>
                                {/* <TableCell align="center">
                                    <StyledBadge
                                        overlap="circle"
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'right',
                                        }}
                                        variant={row.stat === "ONLINE" ? "dot" : "standard"}
                                    >
                                        <Avatar alt="Remy Sharp" src={getAvatar(row.classNumber)} />
                                    </StyledBadge>
                                </TableCell> */}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </React.Fragment>
    )
}

export default Onlines;