
const API_URL: string = (!process.env.NODE_ENV || process.env.NODE_ENV != 'development') ? "http://mufantasy.com.br:8091" : "http://mufantasy.com.br:8091"

//const API_URL: string = "http://muoldfriends.com"

const sendRequest = async (endpoint: string, body?: object, header?: Record<string, string>) => {
    return new Promise((resolve: (r: Response) => void, reject) => {
        fetch(`${API_URL}/${endpoint}`, {
            method: 'POST',
            body: body!=null ? JSON.stringify(body) : undefined,
            headers: {
                Authorization: `${localStorage.getItem('Authorization')}`,
                'Content-Type': `application/json`,
                'Access-Control-Allow-Origin' : '*',
                ...header
            }
        }).then(
            (result) => {
                resolve(result)
            },
            (error) => {
                reject(error);
            }
        )
    })
}

const sendGetRequest = async (endpoint: string, header?: Record<string, string>) => {
    return new Promise((resolve: (r: Response) => void, reject) => {
        console.log(`${API_URL}/${endpoint}`)
        fetch(`${API_URL}/${endpoint}`, {
            method: 'GET',
            headers: {
                Authorization: `${localStorage.getItem('Authorization')}`,
                ...header
            }
        }).then(
            (result) => {
                resolve(result)
            },
            (error) => {
                reject(error);
            }
        )
    })
}

const sendGetRequestWithoutToken = async (endpoint: string, header?: Record<string, string>) => {
    return new Promise((resolve: (r: Response) => void, reject) => {
        console.log(`${API_URL}/${endpoint}`)
        fetch(`${API_URL}/${endpoint}`, {
            method: 'GET',
            headers: {
                ...header
            }
        }).then(
            (result) => {
                resolve(result)
            },
            (error) => {
                reject(error);
            }
        )
    })
}

export { sendRequest, sendGetRequest, sendGetRequestWithoutToken }
export default API_URL