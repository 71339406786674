import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { SvgIconTypeMap } from '@material-ui/core';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import { ServerStat } from '../config/types';

const useStyles = makeStyles((theme) => ({
    sidebarAboutBox: {
        padding: theme.spacing(2),
        // backgroundColor: theme.palette.grey[200],
    },
    sidebarSection: {
        marginTop: theme.spacing(3),
    },
}));

type SidebarProps = {
    status: ServerStat,
    description: string,
    archives: { title: string, url: string }[],
    social: { name: string, icon: OverridableComponent<SvgIconTypeMap> }[],
};

export default function Sidebar(props: SidebarProps) {
    const classes = useStyles();
    const { description, social, status } = props;

    return (
        <Grid item xs={12} md={4}>
            <Paper elevation={0} className={classes.sidebarAboutBox}>
                <Typography variant="h6" gutterBottom>Status</Typography>
                <p>Total Online: {status.online}</p>
                <p>Total de contas: {status.contas}</p>
                <p>Total de personagens: {status.chars}</p>
                <Typography>{description}</Typography>
            </Paper>
            {/* <Typography variant="h6" gutterBottom className={classes.sidebarSection}>
                Notícias
            </Typography>
            {archives.map((archive, index) => (
                <Link display="block" variant="body1" href={archive.url} key={index}>
                    {archive.title}
                </Link>
            ))} */}
            <Typography variant="h6" gutterBottom className={classes.sidebarSection}>
                Social
      </Typography>
            {social.map((network, index) => (
                <Link display="block" variant="body1" href="#" key={index}>
                    <Grid container direction="row" spacing={1} alignItems="center">
                        <Grid item>
                            <network.icon />
                        </Grid>
                        <Grid item>{network.name}</Grid>
                    </Grid>
                </Link>
            ))}
        </Grid>
    );
}

Sidebar.propTypes = {
    archives: PropTypes.array,
    description: PropTypes.string,
    social: PropTypes.array,
    title: PropTypes.string,
};