import { Box, CircularProgress } from '@material-ui/core';
import React, { useEffect, useState } from 'react';

type Props<T> = {
    promise: () => Promise<T>
    onSuccess: (result: T) => JSX.Element
    onRejected?: (reason: any) => React.Component
}

const LoadingSpinner = <T extends any>({ promise, onSuccess, onRejected }: Props<T>) => {
    const [data, setData] = useState<T>();

    useEffect(() => {
        if (!data)
            promise()
                .then((response) => {
                    setData(response)
                })
                .catch((reason) => {
                    if (onRejected)
                        onRejected(reason)
                })
    }, [data, onRejected, setData, promise]);

    return (
        <>
            {
                (data && onSuccess(data))
                ||
                <Box flex={1} alignItems="center" justifyContent="center" display="flex">
                    <CircularProgress />
                </Box>
            }
        </>
    )
}

export default LoadingSpinner;