import { Avatar, Box, FormControl, Grid, IconButton, InputLabel, MenuItem, Select } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import InfoIcon from '@material-ui/icons/Info';
import { KeyboardDatePicker } from '@material-ui/pickers/DatePicker/DatePicker';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { StyledBadge } from '../../components/StyledBadge';
import { sendGetRequest } from '../../config/Api';
import { Class, RankingData } from '../../config/types';
import { useDialog } from '../../utils/Dialog';
import RankingDialog from './RankingInfo';


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            //   margin: theme.spacing(1),
            minWidth: 120,
            width: '100%'
        },
        table: {
            minWidth: 300,
            // maxWidth: 300
        },
    }),
);

const Ranking: React.FC = () => {
    const classes = useStyles()
    const [rankType, setRankType] = useState<string>('0')
    const [rankingData, setRankingData] = useState<RankingData[]>([])
    const [dataInicio, setDataInicio] = useState<string>(moment(new Date()).format('YYYY-MM-').concat('01'))
    const [dataTermino, setDataTermino] = useState<string>(moment().format('YYYY-MM-DD'))
    const dialog = useDialog()
    const [open, setOpen] = React.useState(false)

    const loadRanking = useCallback(async () => {
        try {
            dialog.showLoading('Carregando dados do ranking')
            const url = `characters/ranking?start=${dataInicio}&end=${dataTermino}`
            const response = await sendGetRequest(url)
            if (response && response.status === 200) {
                const data = (await response.json()).response as RankingData[];
                console.log(data)
                switch (rankType) {
                    case "1":
                        setRankingData(data.filter(row => Number(row.classNumber) === 16 || Number(row.classNumber) === 17))
                        break;
                    case "3":
                        setRankingData(data.filter(row => Number(row.classNumber) <= 1))
                        break;
                    case "2":
                        setRankingData(data.filter(row => Number(row.classNumber) === 32 || Number(row.classNumber) === 33))
                        break;
                    case "4":
                        setRankingData(data.filter(row => Number(row.classNumber) === 48))
                        break;
                    default:
                        setRankingData(data)
                }
            }
        } catch (error) {
            //DO NOTHING?
        } finally {
            dialog.closeLoading()
        }
    }, [setRankingData, dataInicio, dataTermino, rankType, dialog])

    useEffect(() => {
        if (dataInicio && dataTermino && rankType) {
            loadRanking();
        }
    }, [dataInicio, dataTermino, rankType, loadRanking]);


    // const classToString = (classId: Class) => {
    //     switch (classId) {
    //         case 0:
    //             return "Dark Wizard";
    //         case 1:
    //             return "Soul Master";
    //         case 16:
    //             return "Dark Knight";
    //         case 17:
    //             return "Blade Knight";
    //         case 32:
    //             return "Fire Elf";
    //         case 33:
    //             return "Muse Elf";
    //         default:
    //             return "Magic Gladiator"
    //     }
    // }

    const getAvatar = useCallback((classId: Class) => {
        let avatar = undefined
        switch (Number(classId)) {
            case 0:
                avatar = 'avatarSM.png'
                break;
            case 1:
                avatar = 'avatarSM.png'
                break;
            case 16:
                avatar = 'avatarBK.png'
                break;
            case 17:
                avatar = 'avatarBK.png'
                break;
            case 32:
                avatar = 'avatarELF.png'
                break;
            case 33:
                avatar = 'avatarELF.png'
                break;
            case 48:
                avatar = 'avatarMG.png'
                break;
        }
        return avatar
    }, [])

    return (
        <React.Fragment>
            <RankingDialog open={open} setOpen={setOpen} />
            <Grid container spacing={2}>
                <Grid item md={4} sm={6} style={{ width: '100%' }}>
                    <KeyboardDatePicker
                        margin="normal"
                        id="date-picker-dialog-start"
                        label="Data inicial"
                        format="DD/MM/YYYY"
                        value={dataInicio}
                        onChange={(e) => {
                            if (e)
                                setDataInicio(moment(e.toDate()).format('YYYY-MM-DD'))
                        }}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                        style={{ width: '100%' }}
                    />
                </Grid>
                <Grid item md={4} sm={6} style={{ width: '100%' }}>
                    <KeyboardDatePicker
                        margin="normal"
                        id="date-picker-dialog-end"
                        label="Data final"
                        format="DD/MM/YYYY"
                        value={dataTermino}
                        onChange={(e) => {
                            if (e)
                                setDataTermino(moment(e.toDate()).format('YYYY-MM-DD'))
                        }}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                        style={{ width: '100%' }}
                    />
                </Grid>
                <Grid item md={4} sm={6} style={{ width: '100%' }}>
                    <Box display="flex" width="100%" height="100%" flexDirection="row" alignItems="bottom" justifyContent="baseline">
                        <FormControl className={classes.formControl} style={{ marginTop: 16 }} >
                            <InputLabel id="demo-simple-select-helper-label">Classe</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={rankType}
                                onChange={(event) => setRankType(String(event.target.value))}
                            >
                                <MenuItem value={0}>Geral</MenuItem>
                                <MenuItem value={1}>Dark Knight</MenuItem>
                                <MenuItem value={2}>Fire Elf</MenuItem>
                                <MenuItem value={3}>Dark Wizard</MenuItem>
                                <MenuItem value={4}>Magic Gladiator</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                </Grid>
                <Grid item md={12} >
                    <label htmlFor="icon-button-file">
                        <IconButton onClick={()=>setOpen(!open)} color="primary" aria-label="upload picture" component="span">
                            <InfoIcon></InfoIcon>
                        </IconButton>
                    </label>
                </Grid>
            </Grid>
            <TableContainer component={Paper} >
                <Table className={classes.table} size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell>#</TableCell>
                            <TableCell align="center">Pontos</TableCell>
                            <TableCell align="center">Personagem</TableCell>
                            <TableCell align="center">Status</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rankingData.map((row, index) => (
                            <TableRow key={index}>
                                <TableCell component="th" scope="row">
                                    {index + 1}
                                </TableCell>
                                <TableCell align="center">{row.points}</TableCell>
                                <TableCell align="center">{row.name}</TableCell>
                                <TableCell align="center">
                                    <StyledBadge
                                        overlap="circle"
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'right',
                                        }}
                                        variant={row.stat === "ONLINE" ? "dot" : "standard"}
                                    >
                                        <Avatar alt="Remy Sharp" src={getAvatar(row.classNumber)} />
                                    </StyledBadge>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </React.Fragment>
    )
}

export default Ranking;